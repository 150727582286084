.header_red {
    color: #FFF;
    background: var(--maincolor);
    line-height: 35px;
}

.header_white__inner {
    display: grid;
    grid-template-columns: 148px 1fr auto auto auto;
    gap: 0 40px;
}
.header_white__inner.overduehide {
    grid-template-columns: 148px 1fr auto auto;
}

.main_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 78px;
    margin: 26px 0;
}
.main_logo img,
.main_logo svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.search_form {
    position: relative;
    margin: 45px 0 0 0;
}
.search_from_main>div:first-child {
    position: absolute;
    width: 100%;
    z-index: 1000;
}
.search_form__results,
.search_form__results_2 {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    min-width: 720px;
    z-index: 10000;
    border-radius: 0 0 5px 5px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
    background: #FFF;
    padding: 0;
    z-index: 1000;
    display: none;
}
.search_form__results.active,
.search_form__results_2.active {
    display: block;
}
.search_form__results__overlay,
.search_form__results__overlay_2 {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 999;
    display: none;
}
.search_form__results__overlay.active,
.search_form__results__overlay_2.active {
    display: block;
}
.search_form__results_hit__info_unit_instock {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: var(--darkgrey);
    border-radius: 50%;
}
.search_form__results_hit__info_unit_instock.not_in_stock {
    background: var(--maincolor);
}
.search_form__results_hit__info_unit_instock.low {
    background: var(--orange);
}
.search_form__results_hit__info_unit_instock.in_stock {
    background: var(--monolithlightgreen);
}
.search_form__results_hit__price {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}
.search_form__results_hit__price__discount {
    line-height: 30px;
    color: #000;
    background: var(--monolithlightyellow);
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 15px;
}
.search_form__results .ais-Hits {
    max-height: calc(100vh - 280px);
    overflow-x: auto;
}
.search_form__results_2 .ais-Hits {
    max-height: calc(100vh - 200px);
    overflow-x: auto;
}
.search_form__results_hit {
    padding: 10px 17px;
    display: grid;
    grid-template-columns: 90px 1fr 120px 90px;
    gap: 25px;
    border-bottom: 1px solid #d9d9d9;
}
.search_form__results_hit__kind {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
}
.search_form__results_hit__img_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
}
.search_form__results_hit__img_wrapper img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.search_form__results_hit__link {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    max-height: 44px;
    overflow: hidden;
    color: var(--darkgrey);
    margin-bottom: 11px;
}
.search_form__results_hit__link:hover {
    color: var(--maincolor);
}
.search_form__results_hit__info {
    display: flex;
    flex-wrap: wrap;
    font-size: .875rem;
    line-height: 1.362em;
}
.search_form__results_hit__info_unit {
    margin-right: 30px;
}
.search_form__input {
    display: block;
    width: 100%;
    line-height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 0 42px 0 27px;
    position: relative;
    z-index: 1001;
}
.search_form__results .ais-Hits-list,
.search_form__results_2 .ais-Hits-list {
    grid-template-columns: 1fr;
    gap: 0;
    margin: 0;
    padding: 0;
}
.search_form__submit {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 32px;
    height: 32px;
    padding: 2px 0 0 0;
    z-index: 1002;
}
.show_all_results {
    display: flex;
    justify-content: center;
    padding: 25px 0;
}

.header_account {
    position: relative;
    margin: 45px 0 0 0;
}
.header_button {
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 0 11px;
    text-align: left;
    color: var(--darkgrey);
    font-weight: 700;
    min-width: 136px;
    margin-right: 12px;
    padding: 0px 10px 10px 10px;
}
.header_account__button__username,
.header_overdue__button__overdue,
.header_cart__amount {
    display: block;
    font-size: 0.875rem;
    line-height: calc(19em / 14);
    font-weight: 400;
    color: #999;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header_cart__button>*, .header_account__button>* {
    pointer-events: none;
}
.header_account__button::after,
.header_cart__button::after,
.fixed_header__icons__account::after {
    content: '';
    position: absolute;
    top: 24px;
    right: -10px;
    width: 10px;
    height: 10px;
    color: var(--darkgrey);
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
}

.header_overdue {
    position: relative;
    margin: 45px 0 0 0;
}
.header_overdue.overduehide {
    display: none;
}
.header_overdue__button {
    color: var(--maincolor);
}
.header_overdue__button__overdue {
    color: var(--maincolor);
}

.header_cart {
    position: relative;
    margin: 45px 0 0 0;
}
.header_cart__popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 89;
    visibility: hidden;
}
.header_cart__popup__wrapper.active {
    visibility: visible;
}
.header_cart__popup__wrapper__container {
    position: relative;
    max-width: var(--maxwidth);
    margin: 0 auto;
    padding: 0 var(--gap);
    height: 100vh;
}
.header_cart__popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: var(--transition);
}
.header_cart__popup {
    position: absolute;
    top: 130px;
    right: 20px;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 22px 28px;
    z-index: 100;
    min-width: 520px;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
}
.header_cart__popup__wrapper.active .header_cart__popup {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.header_was_fixed .header_cart__popup {
    top: 60px;
}
.header_cart__popup__header {
    color: var(--maincolor);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.35em;
    margin-bottom: 4px;
}
.header_cart__popup__status {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.header_cart__popup__status__first {
    color: #999;
}
.header_cart__popup__status__second {
    font-size: 0.875rem;
    line-height: 1.36em;
}
.header_cart__popup__units {
    height: auto;
    max-height: 365px;
    overflow-y: auto;
    margin-bottom: 8px;
    /* border-bottom: 1px solid var(--darkgrey); */
}
.header_cart__popup__unit__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.875rem;
    line-height: 1.36em;
    color: #999;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 10px;
}
.header_cart__popup__unit__table svg path {
    stroke: var(--maincolor) ;
}
.header_cart__popup__unit__table td {
    word-break: break-word;
    padding: 4px 0;
}
.header_cart__popup__unit__table__input {
    width: 50px;
    height: 40px;
    line-height: 38px;
    font-size: 0.875rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    text-align: center;
}
.header_cart__popup__unit__table__title {
    font-weight: 700;
    color: var(--darkgrey);
}
.header_cart__popup__unit__table td.header_cart__popup__unit__table__value {
    text-align: right;
    padding-right: 50px;
    color: var(--darkgrey);
}
.header_cart__popup__unit__table td.total {
    font-size: 1rem;
    color: var(--darkgrey);
    text-align: right;
    padding-right: 50px;
}
.header_cart__popup__summary {
    display: grid;
    grid-template-columns: 215px 1fr 1fr;
    gap: 0 30px;
}
.quick_cart__summary {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 40px;
}
.header_cart__popup__summary__full_cart {
    line-height: 50px;
    border-radius: 5px;
    color: #FFF;
    font-weight: 700;
    background: var(--maincolor);
    text-align: center;
}
.header_cart__popup__summary__cart_total {
    font-size: 0.875rem;
    line-height: 1.4286em;
    font-weight: 700;
    text-align: right;
}
.header_cart__popup__summary__cart_total span {
    display: block;
    font-weight: 400;
    font-size: .75rem;
    line-height: 1.362em;
    color: #999;
}
.header_cart__popup__summary__summ {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    white-space: nowrap;
}
.header_cart__popup__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 23px;
    background: #f8f8f8;
    margin: 20px 0;
    padding: 5px 0;
    border-radius: 0 0 5px 5px;
}
.header_cart__popup__footer__quick_order, .header_cart__popup__footer__all_shopping_carts {
    line-height: 50px;
    border-radius: 5px;
    color: #FFF;
    font-weight: 700;
    background: var(--darkgrey);
}
.header_cart__popup__footer__quick_order:disabled,
.all_shopping_carts__footer__button:disabled,
.header_cart__popup__footer__all_shopping_carts:disabled {
    background: #d6d5d5;
}
.header_cart__popup__footer__all_shopping_carts {
    background: #999;
}
.header_cart__popup__summary__full_cart svg,
.header_cart__popup__footer__quick_order svg,
.header_cart__popup__footer__all_shopping_carts svg,
.all_shopping_carts__footer__button svg {
    position: relative;
    vertical-align: baseline;
    top: 3px;
    margin-left: 10px;
    text-align: center;
}

.all_shopping_carts {
    position: fixed;
    top: 80px;
    left: calc(50% - 260px);
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    min-width: 520px;
    z-index: 1000;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
}
.all_shopping_carts.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.all_shopping_carts__carts {
    max-height: 225px;
    overflow-x: auto;
}
.all_shopping_carts__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    color: var(--maincolor);
    font-weight: 700;
    padding: 22px 29px 4px 29px;
}
.all_shopping_carts__status {
    color: #999;
    padding: 0 29px 25px 29px;
}
.all_shopping_carts__footer {
    padding: 23px 29px 28px 29px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 23px;
    background: #f8f8f8;
    border-radius: 0 0 5px 5px;
}
.all_shopping_carts__footer__button {
    line-height: 50px;
    border-radius: 5px;
    font-weight: 700;
    color: #FFF;
    background: var(--darkgrey);
    text-align: center;
    transition:  0.6s ease 0s;
}
.all_shopping_carts__footer__button:last-child {
    background: #999;
}
.all_shopping_carts__footer__button:hover {
    background: var(--maincolor);
}
.header_cart__popup__footer__quick_order:disabled:hover,
.all_shopping_carts__footer__button:disabled:hover,
.header_cart__popup__footer__all_shopping_carts:disabled:hover {
    background: #d6d5d5;
}
.all_shopping_carts__cart {
    display: block;
    width: 100%;
    color: var(--darkgrey);
    background: #FFF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr auto;
    gap: 0 30px;
    padding: 0 29px;
    font-size: 0.875rem;
    line-height: 50px;
    text-align: left;
}
.all_shopping_carts__cart * {
    pointer-events: none;
}
.all_shopping_carts__cart:hover {
    background: #f2f2f2;
}
.all_shopping_carts__cart svg {
    position: relative;
    top: 16px;
}
.all_shopping_carts__cart:hover svg path {
    stroke: var(--darkgrey)
}

.top_menu {
    padding: 10px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    font-size: 0.875rem;
    line-height: 1.4286em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.01em;
    background: #FFF;
}
.top_menu>ul {
    list-style: none;
    display: flex;
    /* padding-left: 0; */
}
.top_menu>ul li {
    position: relative;
    padding: 0 40px;
}
.top_menu>ul li.havesub::before {
    content: '';
    position: absolute;
    top: 3px;
    right: 15px;
    width: 10px;
    height: 10px;
    color: var(--darkgrey);
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
}
.top_menu>ul li:first-child {
    padding-left: 0;
}
.top_menu>ul li:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 20px;
    background: #d9d9d9;
}
.top_menu>ul a {
    color: var(--darkgrey);
    white-space: nowrap;
}
.top_menu>ul a:hover {
    color: var(--maincolor);
}
.top_menu>ul a.active {
    color: var(--maincolor);
}
.fixed_header .top_menu {
    position: relative;
    transition: var(--transition);
    transform: translateY(-50px);
    z-index: 5;
    visibility: hidden;
}
.fixed_header.active .top_menu.active {
    transform: translateY(0);
    visibility: visible;
}

.close_popup {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 21px;
    height: 21px;
    border-radius: 50%;
}
.close_popup::before, .close_popup::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    width: 1px;
    background: var(--maincolor);
    transform: rotate(45deg);
}
.close_popup::after {
    transform: rotate(-45deg);
}
.close_popup:hover::before, .close_popup:hover::after {
    background: #000;
}

.account_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    visibility: hidden;
}
.account_popup__wrapper.active {
    visibility: visible;
}
.account_popup__wrapper__container {
    position: relative;
    max-width: var(--maxwidth);
    margin: 0 auto;
    padding: 0 var(--gap);
    height: 100vh;
}
.account_popup__overlay {
    position: absolute;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 101;
    background: transparent;
    opacity: 0;
    visibility: hidden;
}
.account_popup__wrapper.active .account_popup__overlay {
    opacity: 1;
    visibility: visible;
}
.account_popup {
    position: absolute;
    top: 130px;
    right: 85px;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    min-width: 520px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    z-index: 110;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30px);
}
.header_was_fixed .account_popup {
    top: 60px;
    right: 20px;
}
.account_popup__wrapper.active .account_popup {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.account_popup::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background: #e6e6e6;
}
.account_popup__header {
    color: var(--maincolor);
    line-height: 50px;
    padding: 6px 30px 0 30px;
    font-weight: 700;
    border-bottom: 1px solid #e6e6e6;
}
.account_popup__list {
    list-style: none;
    padding-bottom: 10px;
}
.account_popup__list a,
.account_popup__list button {
    line-height: 47px;
    display: block;
    width: 100%;
    padding: 0 30px;
    color: var(--darkgrey);
    border-bottom: 1px solid transparent;
    text-align: left;
    white-space: nowrap;
}
.account_popup__list button:disabled {
    line-height: 47px;
    display: block;
    width: 100%;
    padding: 0 30px;
    color: var(--grey);
    border-bottom: 1px solid transparent;
    text-align: left;
    white-space: nowrap;
}
.account_popup__list a:hover,
.account_popup__list button:hover {
    background: #f8f8f8;
}
.account_popup__list a.border_bottom,
.account_popup__list button.border_bottom {
    border-color: #e6e6e6;
}
.account_popup__list a.showppingcartid,
.account_popup__list button.showppingcartid {
    padding-left: 38px;
}
.account_popup__list a svg,
.account_popup__list button svg {
    position: relative;
    top: 15px;
    margin-right: 15px;
}

.quick_order__wrapper,
.change_account__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    visibility: hidden;
}
.quick_order__wrapper.active,
.change_account__wrapper.active {
    visibility: visible;
}
.quick_order__overlay,
.change_account__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
}
.quick_order__wrapper.active .quick_order__overlay,
.change_account__wrapper.active .change_account__overlay {
    visibility: visible;
    opacity: 1;
}
.quick_order__content,
.change_account__content {
    position: absolute;
    top: 10px;
    bottom: 10px;
    width: 1240px;
    left: calc(50% - 620px);
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 30px 40px;
    z-index: 3;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    height: auto;
    max-height: 900px;
    /* height: calc(100vh - 20px); */
}
.change_account__content {
    width: 560px;
    left: calc(50% - 280px);
    top: 10%;
    padding: 5px;
    max-height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.change_account__content ul{
    max-height: 410px !important;
}
.change_account__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 23px;
    margin: 20px 30px;
    padding: 5px 0;
    border-radius: 0 0 5px 5px;
}
.change_account__select {
    padding: 30px;
    max-height: 550px;
}
.change_account__content .change_account__header {
    display: flex;
    justify-content: center;
    font-size: 18px;
    line-height: 1.361875em;
    font-weight: 700;
    margin: 0 0 20px 0;
}
.quick_order__wrapper.active .quick_order__content,
.change_account__wrapper.active .change_account__content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.quick_order__header {
    font-size: 1.25rem;
    line-height: 1.35rem;
    font-weight: 700;
    margin-bottom: 30px;
}
.quick_order__columns {
    display: grid;
    grid-template-columns: 1fr 470px;
    gap: 15px;
    height: auto;
    max-height: 1000px;
    overflow-y: auto;
}
.quick_order__column_first {
    max-height: 810px;
    height: auto;
    overflow-y: auto;
}
.quick_order__status {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 auto;
    background: #d9d9d9;
}
.quick_order__status.green {
    background: #00B707;
}
.quick_order__status.orange {
    background: #FC6738;
}
.quick_order__status.red {
    background: var(--maincolor);
}
.quick_order__table {
    font-size: .875rem;
    line-height: 1.362em;
}
.quick_order__table__first {
    text-align: left;
}
.quick_order__table__first strong,
.quick_order__table__first a.strong {
    display: block;
    margin-bottom: 5px;
    text-decoration: none;
}
.quick_order__table__last_row {
    color: #d9d9d9;
}
.quick_order__trash {
    margin: 0 1rem;
}
.quick_order__content .delivery_addresses__popup__close {
    top: 40px;
    right: 40px;
}
.quick_order__aside__unit__upload {
    display: block;
    text-align: center;
    color: var(--darkgrey);
    text-decoration: underline;
    cursor: pointer;
    margin-top: 23px;
    font-size: .875rem;
    line-height: 1.362em;
}
.quick_order__aside__unit__upload:hover {
    color: var(--maincolor);
}
.quick_order__aside__unit__upload:hover svg path {
    stroke: var(--maincolor);
}
.quick_order__aside__unit {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 15px 24px 12px 24px;
    margin-bottom: 24px;
}
.quick_order__aside__unit__white {
    background: #FFF;
}
.quick_order__aside__unit:last-child {
    margin-bottom: 0;
}
.quick_order__aside__unit__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    font-weight: 700;
    margin-bottom: 12px;
}
.quick_order__aside__unit__text {
    margin-bottom: 1rem;
}
.aside_cart_summary {
    position: relative;
    background: #F8F8F8;
    border-radius: 5px;
    padding: 15px 24px 10px 24px;
    margin-bottom: 15px;
    transition: var(--transition);
}
.aside_cart_summary.wrapped {
    max-height: 90px;
    overflow: hidden;
}
.aside_cart_summary__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    font-weight: 700;
    margin-bottom: 4px;
}
.aside_cart_summary__above_header {
    display: grid;
    grid-template-columns: 1fr auto;
    color: #999;
    font-size: .875rem;
    line-height: 1.362em;
    margin-bottom: 16px;
}
.aside_cart_summary .header_cart__popup__unit__table td.header_cart__popup__unit__table__value,
.aside_cart_summary .header_cart__popup__unit__table td.total {
    padding-right: 10px;
}
.aside_cart_summar__arrow {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 18px;
    height: 18px;
    padding: 5px 0;
    transition: var(--transition);
}
.aside_cart_summar__arrow svg path {
    stroke: var(--maincolor) ;
}
.aside_cart_summary.wrapped .aside_cart_summar__arrow {
    transform: rotate(180deg);
}

.quantity_group {
    display: grid;
    grid-template-columns: 40px 40px 40px;
    margin: 0 1rem;
}
.quantity_group input {
    line-height: 38px;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
}
.quantity_group__plus, .quantity_group__minus {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    font-size: 1.5rem;
}
.quantity_group__plus {
    color: #FFF;
    background: #00B707;
    border-radius: 0 5px 5px 0;
}
.quantity_group__minus {
    border-radius: 5px 0 0 5px;
    background: #FFF;
    color: var(--darkgrey);
}

/* fixed header */
.fixed_header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    z-index: 10;
    transition: var(--transition);
    transform: translateY(-60px);
}
.fixed_header.active {
    transform: translateY(0);
}
.fixed_header__bg {
    position: relative;
    background: #f3f3f3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
.fixed_header__wrapper {
    display: grid;
    grid-template-columns: 25px 87px 1fr auto;
    gap: 0 40px;
    height: 55px;
}
.fixed_header__logo svg {
    display: block;
    position: relative;
    top: 5px;
    width: 87px;
    height: auto;
}
.fixed_header__menu_button {
    position: relative;
    margin-top: 0.875rem;
    width: 25px;
    height: 25px;
}
.fixed_header__menu_button span {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    width: 19px;
    height: 3px;
    background: var(--maincolor);
    border-radius: 2px;
    transition: var(--transition);
}
.fixed_header__menu_button span:nth-child(2) {
    top: 11px;
}
.fixed_header__menu_button span:nth-child(3) {
    top: 17px;
}
.fixed_header__menu_button.active span:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
}
.fixed_header__menu_button.active span:nth-child(2) {
    transform: scaleY(0);
}
.fixed_header__menu_button.active span:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
}
.fixed_header__search_form {
    position: relative;
    margin: 8px 0 0 0;
}
.fixed_header__search_form input:focus-visible,
.default__header_search_input:focus-visible,
.ais-SearchBox-form input:focus-visible {
    outline: none
}
.fixed_header__search_form__input {
    line-height: 38px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 0 42px 0 25px;
}
.fixed_header__icons {
    margin: 7px 0 8px 0;
    height: 40px;
    display: flex;
    white-space: nowrap;
}
.header__button_min_width{
    min-width: 0px !important;
}
.header_account__button_small::after,
.header_info__button_small::after{
    top: 15px;
    right: 0px;
    pointer-events: none;
    min-width: 0px !important;
}
.header_info__button_small{
    padding: 0;
    margin-right: 5px !important;
}
.header_info__button_small::after {
   display: none;
}
.fixed_header__icons__account__wrapper {
    display: inline-block;
    position: relative;
    margin-right: 44px;
}
.fixed_header__icons__account::after {
    top: 13px;
    right: -22px;
}
.fixed_header__icons__overdue {
    margin-right: 20px;
}
.fixed_header__icons__overdue.overduehide {
    display: none;
}
.fixed_header__icons .header_button {
    display: inline-grid;
}
.fixed_header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 87px;
    height: 55px;
}
.fixed_header__logo img,
.fixed_header__logo svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
/* fixed header */

/* cart grey popup */
.cart_grey_popup {
    position: fixed;
    bottom: 105px;
    right: 30px;
    color: #FFF;
    background: #999;
    font-weight: 700;
    z-index: 99;
    padding: 16px 15px 17px 15px;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1em;
}
.cart_icon_round {
    display: block;
    width: 44px;
    height: 44px;
    background: #FFF;
    border-radius: 50%;
    padding: 12px 0 0 10px;
    margin: 0 auto 12px auto;
}
.cart_icon_round svg path {
    stroke: var(--maincolor) ;
}
.cart_grey_popup__items {
    font-weight: 400;
    font-size: .875rem;
    line-height: 1em;
    padding-bottom: 5px;
}
.scroll_button .cart_icon_round {
    margin: 0 auto 8px auto;
}
.scroll_button_top {
    position: fixed;
    bottom: 240px;
    right: 30px;
    color: #FFF;
    background: var(--maincolor);
    font-weight: 700;
    z-index: 99;
    padding: 13px 15px 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1em;
    transition: 1.2s ease 0s;
    transform: translateY(110px);
}
.scroll_button_top_active {
    transition: 1.5s ease 0s;
    transform: translateY(0px);
}
.scroll_button_top_text {
    text-align: center;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1em;
}
/* cart grey popup */

.find_button {
    position: absolute;
    top: 12px;
    right: 12px;
}
.header .ais-SearchBox-form input[type="search"],
.fixed_header .ais-SearchBox-form input[type="search"] {
    margin: 0;
    padding: 0 42px 0 25px;
}

/* debts icon account blocked alert */
.header__debts_account_blocked_main:hover > div,
.header__debts_account_blocked:hover > div {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.header__debts_account_blocked > div,
.header__debts_account_blocked_main > div {
    width: max-content;
    max-width: 330px;
}
.header__debts_account_blocked > div,
.header__debts_account_blocked_main > div {
    position: absolute;
    z-index: 15;
    padding: 5px 10px 5px 17px;
    border: 1px solid var(--400, #D9D9D9);
    top: 50%;
    right: 35px;
    border-radius: 5px 0px 5px 5px;
    background-color: var(--ligthmain);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    white-space: pre-wrap;

    color: #727272;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.header__debts_account_blocked_main > div {
    top: 15%;
    right: 165px;
}
.header__debts_account_blocked svg circle,
.header__debts_account_blocked svg path {
    stroke: var(--maincolor) ;
}

.default__header_search_input {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: block;
    line-height: 38px;
    padding: 0 42px 0 27px;
    width: 100%;
    padding-left: 25px;
}

.default__header_search_input::before {
    content: "\f002";
    /* font-family: FontAwesome; */
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #999;
}

/* debts icon account blocked alert */

@media screen and (min-height: 800px) {
    /* .header_cart__popup__units {
        max-height: 450px;
    }  */
    .quick_order__column_first {
        max-height: 620px;
    }
}
@media screen and (max-height: 960px) {
    .header_white__inner {
        gap: 0 20px;
    }
}