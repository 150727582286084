@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/css/flag-icons.min.css');

/* Marketnig */
.marketing_two_columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 70px;
}
.marketing__buttons {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 18px 22px;
    margin: 0 0 0 1.5rem;
}
/* .marketing_intro_image img {
    max-width: 100%;
    height: auto;
    padding-right: 40px;
} */
.marketing__button,
.marketing__button_booklets {
    display: block;
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    color: var(--darkgrey);
    padding: 55px 0 43px 0;
    /* text-transform: capitalize; */
}
.marketing__button::first-letter,
.marketing__button_booklets::first-letter {
    text-transform: uppercase;
}
.marketing__button:hover,
.marketing__button_booklets:hover {
    border-color: var(--maincolor);
    color: var(--maincolor);
}
.marketing__button svg,
.marketing__button_booklets svg {
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto 21px auto;
}
.marketing__button:hover svg path,
.marketing__button_booklets:hover svg path {
    stroke: var(--maincolor);
}
.marketing__button_booklets:hover svg path:nth-last-child(2),
.marketing__button_booklets:hover svg path:nth-last-child(1) {
    stroke: none;
    fill: var(--maincolor);
}
.marketing_four_banners {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
.marketing_four_banners img {
    width: 100%;
    height: auto;
    cursor: pointer;
}
.marketing__discover_whats_hot {
    padding-bottom: 40px;
}
@media screen and (max-width:1120px) {
    .marketing__button_booklets,
    .marketing__button {
        padding: 20px 0;
    }
}
/* Marketnig */

/* Contacts */
.contacts__office_support {
    display: grid;
    grid-template-columns: 3fr 4fr 5fr;
    gap: 20px;
}
.contacts__support__header {
    margin: 15px 0;
    font-weight: 700;
}
.contacts__support__unit {
    display: grid;
    grid-template-columns: 70px 1fr 136px;
    gap: 0 43px;
    background: #f8f8f8;
    padding: 29px 37px 38px 23px;
    margin-bottom: 20px;
    /* height: 210px; */
}
.contacts__support__unit.buttons_bottom {
    grid-template-columns: 70px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: ". ." "contacts__support__unit__buttons contacts__support__unit__buttons"; 
}
.contacts__support__unit.buttons_bottom .contacts__support__unit__buttons {
    grid-area: contacts__support__unit__buttons;
}
.contacts__support__unit.buttons_bottom .contacts__support__unit__buttons {
    display: flex;
    margin-top: 30px;
}
.contacts__support__unit.buttons_bottom .contacts__support__unit__buttons button:not(:last-child),
.contacts__support__unit.buttons_bottom .contacts__support__unit__buttons a:not(:last-child) {
    margin-bottom: 0;
    margin-right: 20px;
}
.contacts__support__unit:last-child {
    margin-bottom: 0;
}
.contacts__support__image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.contacts__support__name {
    font-weight: 700;
    margin-bottom: 3px;
}
.contacts__support__post {
    margin-bottom: 21px;
    font-weight: 500;
}
.contacts__support__email,
.contacts__support__phone {
    display: block;
    color: var(--darkgrey);
    margin-bottom: 1px;
}
.contacts__support__email:hover,
.contacts__support__phone:hover {
    color: var(--maincolor);
}
.contacts__support__unit__buttons button:not(:last-child),
.contacts__support__unit__buttons a:not(:last-child) {
    margin-bottom: 20px;
}
.contacts__office_warehouse {
    background: #f8f8f8;
    border-radius: 5px;
    padding: 36px 33px 20px 42px;
}
.contacts__warehouse {
    display: flex;
    height: 500px;
    /* border: 5px; */
}
.contacts__warehouse img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.contacts__office_warehouse__address_header {
    font-weight: 700;
    margin-bottom: 11px;
}
.contacts__office_warehouse__address {
    /* margin-bottom: 14px; */
    display: block;
}
.contacts__three_columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.contacts__three_columns>* {
    background: #f8f8f8;
    border-radius: 5px;
    padding: 35px 37px 37px 39px;
}
.contacts__three_columns__table {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 10px;
}
.contacts__three_columns__table a {
    color: var(--darkgrey);
}
.contacts__three_columns__table a.contacts__email {
    color: var(--maincolor);
}
.contacts__three_columns__table a.contacts__email:hover {
    color: var(--darkgrey);
}
.contacts_hr {
    margin: 63px 0 57px 0;
}
@media screen and (max-width:1120px) {
    .marketing__button_booklets,
    .marketing__button {
        padding: 20px 0;
    }
}
/* Contacts */

/* Brands */
.brands__search {
    width: 50%;
}
.brands_alphabet {
    margin: 42px 0 55px 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-weight: 700;
    color: #d9d9d9;
}
.brands_alphabet a {
    color: var(--darkgrey);
}
.brands_alphabet a:hover {
    color: var(--maincolor);
}
.brands_alphabet a[data-length="0"] {
    color: #d9d9d9;
    pointer-events: none;
    cursor: default;
}
.brands__big_letter {
    font-size: 1.5rem;
    line-height: 1.36em;
    font-weight: 700;
    margin-bottom: 20px;
}
.brands__single_brand__info {
    max-height: 0;
    overflow-y: hidden;
    border: 1px solid transparent;
    border-radius: 5px;
}
.brands__single_brand.active .brands__single_brand__info {
    margin-top: 15px;
    padding: 23px 28px;
    max-height: none;
    border-color: #d9d9d9;
}
.brands__single_brand__info__footer {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 20px;
    margin-top: 20px;
}
.brands__single_brand__info__footer__social {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.brands__single_brand__info__footer__social a {
    margin-right: 15px;
}
.brands_by_letter__wrapper {
    margin-bottom: 50px;
}
.brands_letters.in_search .brands_by_letter__wrapper {
    margin-bottom: 0;
}
.brands_by_letter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
.brands__single_brand__title {
    position: relative;
    font-weight: 600;
    padding-right: 30px;
    color: var(--darkgrey);
    cursor: pointer;
    text-align: left;
}
.brands__single_brand__title span {
    pointer-events: none;
}
.brands__single_brand__title:hover {
    color: var(--maincolor);
}
.brands__single_brand__title::after {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    width: 7px;
    height: 7px;
    border-bottom: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: var(--transition);
}
.brands__single_brand.active .brands__single_brand__title::after {
    transform: translateY(3px) rotate(225deg);
}
.search_content_placeholder {
    width: 50%;
    height: 50px;
    margin-bottom: 42px;
}
.alphabet_placeholder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
}
.alphabet_placeholder li {
    display: block;
    width: 5rem;
}
.brandplaceholdercolumn {
    width: 10rem;
    margin-bottom: 1.5rem;
}
.brandplaceholderrow {
    display: flex;
    justify-content: space-between;
}
/* Brands */

/* 404 */
.big404 {
    font-size: 6.25em;
    line-height: 1.36em;
    color: #f2f2f2;
    font-weight: 700;
    margin-top: 124px;
}
.page404 h1 {
    padding: 0;
    margin-top: -15px;
    margin-bottom: 27px;
}
.page404 h2 {
    margin-bottom: 40px;
}
.help_404 {
    font-weight: 600;
    margin: 17px 0 40px 0;
}
.search_form_404 {
    max-width: 760px;
    margin: 0 auto 89px auto;
}
/* 404 */

/* My Account */
.myaccount__hello {
    font-weight: 600;
    margin-bottom: 15px;
}
.myaccount__two_columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 15px;
    gap: 40px;
}
.myaccount__buttons {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 56px;
}
.myaccount__button {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 210px;
    text-align: center;
    color: var(--darkgrey);
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 55px 10px 0 10px;
    font-weight: 600;
    vertical-align: baseline;
}
.myaccount__buttons__small {
    display: block;
    font-size: .875rem;
    line-height: 1.362em;
    margin-top: 5px;
    font-weight: 400;
}
.myaccount__button:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.myaccount__button svg {
    display: block;
    margin: 0 auto 21px auto;
}
.myaccount__button.myaccount__button__showppingcarts svg {
    margin: 5px auto 21px auto;
}
.myaccount__button.feedback svg {
    margin-bottom: 26px;
}
.myaccount__button.marketing svg {
    margin-bottom: 32px;
}
.myaccount__buttons__language {
    display: block;
    margin: -10px auto 21px auto;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.myaccount__button__lang {
    padding-bottom: 20px;
}
.myaccount__button:hover svg path,
.myaccount__button:hover svg rect,
.myaccount__button:hover svg line {
    stroke: var(--maincolor);
}
.myaccount__button:hover svg circle {
    fill: var(--maincolor);
}
.myaccount__buttons__alert {
    position: absolute;
    top: 51px;
    left: calc(50% + 8px);
    width: 24px;
    line-height: 24px;
    text-align: center;
    background: var(--maincolor);
    font-size: 12px;
    color: #FFF;
    border-radius: 50%;
}
.account_lang_selector__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    z-index: 1000;
}
.account_lang_selector__wrapper.active {
    visibility: visible;
}
.account_lang_selector__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    transition: var(--transition);
    opacity: 0;
}
.account_lang_selector__wrapper.active .account_lang_selector__overlay {
    opacity: 1;
}
.account_lang_selector__wrapper .login_form__lang_selector__wrapper {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0);
    z-index: 2;
    transition: var(--transition);
}
.account_lang_selector__wrapper.active .login_form__lang_selector__wrapper {
    transform: translateX(-50%) translateY(-50%) scale(1);
}
/* My Account */
@media screen and (max-width:1160px) {
    .contacts__support__unit {
        display: grid;
        grid-template-columns: none;
        gap: 20px 43px;
    }
}
/* Promotional Booklets & Product Catalog */
.promotions__two_columns {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0 40px;
}
.promotions__two_columns__first {
    text-align: center;
}
.promotional_booklet__main_image {
    object-fit: contain;
    width: 100%;
    margin: 0 auto 20px auto;
    box-shadow: 0 0 20px rgba(0,0,0,.15);
}
.promotions__two_columns__first__current_booklet {
    font-weight: 600;
    margin-bottom: 4px;
}
.not_catalog {
    margin-top: 30%;
}
.promotions__two_columns__second {
    padding: 130px 0 0 0;
}
.promotions__two_columns__text {
    margin: 14px 0 84px 0;
}
.all_promotional_booklets {
    text-align: center;
    margin: 23px 0 26px 0;
    font-weight: 700;
}
.promotions__booklets {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 50px 14px;
}
.promotions__booklet {
    position: relative;
    padding: 3px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}
.promotions__booklet__img {
    /* height: 240px; */
    display: flex;
    align-items: center;
}
.promotions__booklet:hover {
    background: #f2f2f2;
}
.promotions__booklet.current {
    color: #FFF;
    background: var(--maincolor);
}
.promotions__booklet * {
    pointer-events: none;
}
.promotions__booklet__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.promotions__booklet.current::before {
    content: 'Currently Viewing';
    position: absolute;
    left: 0;
    right: 0;
    top: -38px;
    color: #FFF;
    font-size: .875rem;
    line-height: 41px;
    background: var(--maincolor);
    border-radius: 5px 5px 0 0;
}
.promotions__booklet__dates {
    font-size: .875rem;
    line-height: 34px;
}
/* .pdf_thumb {
    width: 100px;
    height: auto;
} */
/* Promotional Booklets & Product Catalog */

/* Shopping Carts */
.cart_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    visibility: hidden;
}
.cart_popup__wrapper.active {
    visibility: visible;
}
.cart_popup__content {
    position: absolute;
    top: 120px;
    width: 482px;
    left: calc(50% - 241px);
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    z-index: 3;
    padding: 32px 30px 37px 30px;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
}
.cart_popup__wrapper.active .cart_popup__content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.cart_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: rgba(0,0,0,.15); */
    z-index: 2;
    visibility: hidden;
}
.cart_popup__wrapper.active .cart_popup__overlay {
    visibility: visible;
}
.cart_popup__header {
    color: var(--maincolor);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.35em;
    margin-bottom: 4px;
}
.cart_popup__r_u_sure {
    color: #999;
    margin-bottom: 21px;
}
.cart_popup__data {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    margin-bottom: 29px;
    padding-right: 40px;
}
.cart_popup__data>div:last-child {
    text-align: right;
}
.cart_popup__data__def {
    font-weight: 600;
    margin-bottom: 3px;
}
.cart_popup__buttons {
    padding: 0 0 0 62px;
}
.cart_popup__buttons .btn:last-child {
    margin-left: 23px;
}
/* Shopping Carts */

/* Debts */
.overduedebts_table {
    margin: 9px 0 44px 0;
}
.debts_table {
    margin: 9px 0 0 0;
}
/* Debts */

/* Invoices */
.payment_status, .return_status, .download_button {
    margin: 0 auto;
    line-height: normal;
}
.payment_status.pending {
    color: var(--monolithgreen);
}
.payment_status.paid {
    color: var(--maincolor);
}
.return_status.paid svg path {
    stroke: #d9d9d9;
}
.disabled_color svg path {
    stroke: #d9d9d9;
}
.invoices_process {
    color: var(--darkgrey);
    text-decoration: underline;
}
.invoices_process:hover {
    color: var(--maincolor);
}
/* Invoices */

/* Return products */
.return_products__please {
    font-weight: 700;
    margin: 0 0 24px 0;
}
.return_products__two_columns {
    --returnasidewidth: 280px;
    display: flex;
    justify-content: space-between;
}
.return_products__two_columns__first {
    width: calc(100% - var(--returnasidewidth) - var(--gap));
}
.return_products__two_columns__second {
    width: var(--returnasidewidth);
    position: sticky;
    top: 75px;
    max-height: calc(100vh - 75px);
}
.return_products__aside__unit {
    padding: 30px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-bottom: 33px;
}
.return_products__aside__unit:last-child {
    background: #FFF;
    margin-bottom: 0;
}
.return_products__aside__unit__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    font-weight: 700;
    color: var(--maincolor);
    margin-bottom: 21px;
}
.return_products__aside__unit .cart_aside__table>div:last-child {
    font-weight: 400;
}
.return_products__aside__unit .cart_aside__table {
    margin-bottom: 22px;
}
.select_quantities[data-selected="0"] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .75;
}
.select_quantities svg {
    top: 14px;
}
.reason_details {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: 74px;
    background: #f2f2f2;
    border-radius: 5px;
    padding: 18px 34px;
    line-height: 50px;
    font-weight: 600;
}
.local_input {
    display: none;
}
.input_renderer_returns {
    display: grid;
    grid-template-columns: 40px 40px 40px;
}
.input_renderer_returns__button {
    line-height: 40px;
    border: 1px solid #d9d9d9;
    background: #FFF;
    font-size: 20px;
    border-radius: 5px 0 0 5px;
}
.input_renderer_returns__button.plus {
    color: #FFF;
    background: var(--monolithlightgreen);
    border-color: var(--monolithlightgreen);
    border-radius: 0 5px 5px 0;
}
.plus:disabled {
    background: var(--grey) !important;
    border-color: var(--grey) !important;
}
.input_renderer_returns__input {
    line-height: 38px;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
}
/* Return products */

/* return details */
.return_reason__no_change {
    line-height: 42px;
    color: var(--darkgrey);
}
.return_reason__no_change strong {
    font-weight: 600;
}
/* return details */

/* Process */
.process_grey {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 15px;
    margin-bottom: 38px;
}
.process_grey>div {
    background: #F8F8F8;
    padding: 45px 42px 42px 42px;
}
.process_grey__header {
    font-weight: 700;
}
.process_grey__three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.process_grey__three__def {
    font-weight: 600;
    margin-bottom: 10px;
}
/* Process */

/* Complaints */
.complaints_columns {
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 40px;
}
.complaints_grey {
    color: #FFF;
    background: #999999;
    border-radius: 5px;
    padding: 41px 46px 38px 46px;
    margin-bottom: 37px;
}
.complaints_grey__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    margin-bottom: 20px;
    font-weight: 700;
}
.complaints_grey__text {
    margin-bottom: 24px;
}
.complaints_grey__link {
    font-weight: 600;
    color: #FFF;
}
.complaints_grey__link:hover {
    color: #f2f2f2;
}
.complaints_grey__link svg {
    margin-right: 10px;
}
.complaints_grey__link:hover svg path {
    stroke: #f2f2f2;
}
.account_options {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 30px;
}
.account_options a {
    display: block;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    font-weight: 600;
    text-align: center;
    padding: 55px 10px 0 10px;
    height: 190px;
}
.account_options a:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.account_options a svg {
    display: block;
    margin: 0 auto 21px auto;
}
.account_options a:hover svg path,
.account_options a:hover svg circle {
    stroke: var(--maincolor)
}
.complaints_status.resolved {
    color: var(--monolithgreen);
}
.complaints_status.pending {
    color: #FC6738;
}
.complaints_hr {
    margin: 104px 0 67px 0;
}
.complaints_table {
    margin-top: 9px;
}
/* Complaints */

/* Submit Complaint */
.submitcomplaint_columns {
    display: grid;
    grid-template-columns: 4fr 3fr;
    gap: 40px;
}
.submitcomplaint_form {
    display: grid;
    grid-template-columns: 2fr 5fr;
    gap: 20px;
}
.submitcomplaint_form label {
    line-height: 50px;
    font-weight: 600;
}
.have_you_spoken {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 38px;
}
.have_you_spoken__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    margin-bottom: 20px;
}
.have_you_spoken__text {
    line-height: 1.625em;
    margin-bottom: 24px;
}
.have_you_spoken__link {
    color: var(--darkgrey);
    font-weight: 600;
}
.have_you_spoken__link:hover {
    color: var(--maincolor);
}
.have_you_spoken__link svg {
    margin-right: 10px;
}
.have_you_spoken__link:hover svg path {
    stroke: var(--maincolor)
}
.your_designated_support_team {
    font-weight: 700;
    margin: 40px 0;
}
.your_designated_support_team.on_thanks_page {
    margin: 25px 0 25px 0;
}
.no_top_margin {
    margin-top: 0;
}
.btn-label {
    width: 254px;
}
.btn-label input[type="file"] {
    display: none;
}
.submitcomplaint_checkbox {
    margin-right: 1em;
}
.submitcomplaint_form label.submitcomplaint_checkbox_label {
    line-height: 1.625em;
    font-weight: 400;
}
.submitcomplaint_form label.submitcomplaint_checkbox_label a {
    color: var(--darkgrey);
    text-decoration: underline;
}
.submitcomplaint_form label.submitcomplaint_checkbox_label a:hover {
    color: var(--maincolor);
}
/* Submit Complaint */

/* Single Complaint */
.complaint_columns {
    display: grid;
    grid-template-columns: 1fr 30%;
    gap: 40px;
}
.complaint_grey_info {
    background: #F2F2F2;
    border-radius: 5px;
    padding: 27px 41px;
    margin-bottom: 41px;
}
.complaint_grey_info__header, .complaint_reply__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    margin-bottom: 20px;
}
.complaint_grey_info__under_header, .complaint_reply__under_header {
    font-size: .875rem;
    line-height: 1.362em;
    margin-bottom: 35px;
}
.complaint_details {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 20px 30px;
}
.complaint_details__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    margin-bottom: 28px;
}
.complaint_details .cart_aside__table>div:last-child {
    font-weight: 400;
}
.complaint_reply {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 26px 40px;
    margin-bottom: 42px;
}
.complaint__add_reply {
    padding-bottom: 40px;
}
.complaint__add_file {
    margin-top: 15px;
    margin: 15px 0 24px 0;
    display: block;
    width: 254px;
}
.complaint__add_file input[type="file"] {
    display: none;
}
.update_complaint {
    margin-top: 20px;
}
.complaint_attachments {
    font-weight: 600;
    margin: 24px 0 17px 0;
}
.complaint_attachments__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}
.complaint_attachments__list li {
    margin-right: 10px;
}
.complaint_attachments__list a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}
.complaint_attachments__list a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* Single Complaint */

/* Delivery Addresses */
.delivery_addresses__columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.delivery_addresses__buttons {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
.delivery_addresses__buttons a {
    position: relative;
    display: block;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    height: 190px;
    padding: 55px 10px 0 10px;
    text-align: center;
    font-weight: 600;
    color: var(--darkgrey);
}
.delivery_addresses__buttons a.delivery_addresses__buttons__shoppingcarts {
    padding-top: 60px;
}
.delivery_addresses__buttons a:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.delivery_addresses__buttons a svg {
    display: block;
    margin: 0 auto 21px auto;
}
.delivery_addresses__buttons a:hover svg path {
    stroke: var(--maincolor);
}
.delivery_addresses__buttons__alert {
    position: absolute;
    top: 51px;
    left: calc(50% + 8px);
    width: 24px;
    line-height: 24px;
    text-align: center;
    font-size: .875rem;
    font-weight: 600;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
}
.delivery_addresses__info {
    background: #F8F8F8;
    border-radius: 5px;
    padding: 34px 42px;
    margin-bottom: 76px;
}
.delivery_addresses__info__header {
    font-weight: 700;
}
.delivery_addresses__info__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.delivery_addresses__info__grid__def {
    font-weight: 600;
    margin-bottom: 10px;
}
.delivery_addresses__table {
    margin-top: 25px;
}
.current_tr_1 {
    color: var(--maincolor);
}
.delivery_addresses__table .star {
    margin: 0 1rem;
    display: none;
}
.delivery_addresses__table .current_tr_1 .star {
    display: block;
}
.delivery_addresses__more {
    width: 24px;
    height: 24px;
}
.active>.delivery_addresses__more svg path,
.delivery_addresses__more:hover svg path {
    stroke: var(--maincolor);
}
.delivery_addresses__table_menu__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    visibility: hidden;
}
.delivery_addresses__table_menu__overlay.active {
    visibility: visible;
}
.delivery_addresses__table_menu {
    position: absolute;
    top: -56px;
    right: 40px;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: var(--transition);
    opacity: 0;
    visibility: hidden;
    transform: translateX(20px);
    z-index: 100;
}
.delivery_addresses__table_menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
}
.delivery_addresses__table_menu a,
.delivery_addresses__table_menu button {
    background: #F8F8F8;
    font-size: .875rem;
    line-height: 45px;
    display: block;
    width: 100%;
    text-align: left;
    padding: 0 20px;
    color: #666;
    border-bottom: 1px solid #D9D9D9;
}
.delivery_addresses__table_menu a:hover,
.delivery_addresses__table_menu button:hover {
    background: #f2f2f2;
}
.delivery_addresses__table_menu a:first-child,
.delivery_addresses__table_menu button:first-child {
    border-radius: 5px 5px 0 0;
}
.delivery_addresses__table_menu a:last-child,
.delivery_addresses__table_menu button:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 0 none;
}
.delivery_addresses__header_button {
    display: flex;
    justify-content: space-between;
}

.delivery_addresses__popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    visibility: hidden;
}
.delivery_addresses__popup__wrapper.active {
    visibility: visible;
}
.delivery_addresses__popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.15);
    visibility: hidden;
}
.delivery_addresses__popup__wrapper.active .delivery_addresses__popup__overlay {
    visibility: visible;
}
.delivery_addresses__popup__content {
    position: absolute;
    top: 70px;
    width: 982px;
    left: calc(50% - 491px);
    background: #FFFFFF;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    z-index: 3;
    padding: 36px;
    transition: var(--transition);
    transform: translateY(-50px);
    visibility: hidden;
    opacity: 0;
}
.delivery_addresses__popup__wrapper.active .delivery_addresses__popup__content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.delivery_addresses__popup__content__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    color: var(--maincolor);
    font-weight: 700;
    margin-bottom: 31px;
}
.delivery_addresses__popup__rows {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 1px;
}
.delivery_addresses__popup__row {
    margin-bottom: 20px;
}
.delivery_addresses__popup__row.two_columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 37px;
}
.delivery_addresses__popup__row.one_two_columns {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 37px;
}
.delivery_addresses__popup__label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}
.delivery_addresses__popup__close {
    position: absolute;
    top: 39px;
    right: 28px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.delivery_addresses__popup__close::before,
.delivery_addresses__popup__close::after {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 9px;
    width: 1px;
    background: #999;
    transform: rotate(45deg);
}
.delivery_addresses__popup__close::after {
    transform: rotate(-45deg);
}
.delivery_addresses__popup__close:hover {
    border-color: var(--maincolor);
}
.delivery_addresses__popup__close:hover::before,
.delivery_addresses__popup__close:hover::after {
    background: var(--maincolor);
}
.form-control-select > div > div > div input{
height: 36px;
} 
/* Delivery Addresses */

/* Thank You */
.thank_you__columns {
    display: grid;
    grid-template-columns: 1fr 41%;
    gap: 40px;
}
.thank_you__red_alert {
    color: #FFF;
    background: var(--maincolor);
    padding: 41px 46px;
    margin-bottom: 40px;
}
.thank_you__red_alert__header {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
    margin-bottom: 20px;
}
.thank_you__red_alert__text {
    margin-bottom: 26px;
}
.thank_you__red_alert__link {
    color: #FFF;
    font-weight: 600;
}
.thank_you__red_alert__link:hover {
    opacity: .95;
}
.thank_you__red_alert__link svg {
    margin-left: 10px;
}
.thank_you__buttons {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 30px;
}
.thank_you__buttons a {
    display: block;
    position: relative;
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    color: var(--darkgrey);
    font-weight: 600;
    text-align: center;
    padding: 55px 10px 0 10px;
    height: 190px;
}
.thank_you__buttons a:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.thank_you__buttons a svg {
    display: block;
    margin: 0 auto 20px auto;
}
.thank_you__buttons a.shoppingcarts_margin svg {
    margin-bottom: 25px;
}
.thank_you__buttons a:hover svg path {
    stroke: var(--maincolor);
}
.thank_you__buttons__alert {
    position: absolute;
    top: 53px;
    left: calc(50% + 11px);
    width: 24px;
    line-height: 24px;
    font-size: .875em;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
}
/* Thank You */

/* Returns */
.aggrid-link {
    color: var(--darkgrey);
    text-decoration: underline;
}
.aggrid-link:hover {
    color: var(--maincolor);
}
.returns_download_button {
    margin: 0 auto;
}
/* Returns */

/* Return Registered */
.return_registered__columns {
    display: grid;
    grid-template-columns: 1fr 40%;
    gap: var(--gap);
}
.return_registered__red {
    padding: 41px 38px 53px 46px;
    margin-bottom: 40px;
    border-radius: 5px;
    color: #FFF;
    background: var(--maincolor);
}
.return_registered__red__button {
    color: #FFF;
    line-height: 24px;
    height: 28px;
    font-weight: 600;
    margin-top: 26px;
}
.return_registered__red__button svg {
    margin-left: 10px;
}
.return_registered__grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
.return_registered__grid a {
    position: relative;
    display: block;
    height: 190px;
    text-align: center;
    color: var(--darkgrey);
    background: #f2f2f2;
    padding: 55px 10px 0 10px;
    font-weight: 600;
}
.return_registered__grid a:hover {
    color: var(--maincolor);
}
.return_registered__grid a svg {
    display: block;
    margin: 0 auto 21px auto;
}
.return_registered__grid a:hover svg path {
    stroke: var(--maincolor);
}
/* Return Registered */

/* Videos */
.videos_columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 35px;
}
/* .video_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    background: var(--maincolor);
} */
/* .video_placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */
.videos__main_description {
    padding: 16px 0 58px 0;
}
.videos_grid {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 26px 0 0 0;
}
.videos_grid li {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    background: #f8f8f8;
    cursor: pointer;
}
.videos_grid li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video_wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    visibility: hidden;
}
.video_wrapper.active {
    visibility: visible;
}
.video_overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    /* background: rgba(0, 0, 0, 0.15); */
    visibility: hidden;
}
.video_wrapper.active .video_overlay {
    visibility: visible;
}
.video_content {
    position: absolute;
    top: 80px;
    /*
    width: 952px;
    left: calc(50% - 476px);
    */
    width: 700px;
    left: calc(50% - 350px);
    background: #FFF;
    z-index: 3;
    padding: 26px 32px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
}
.video_wrapper.active .video_content {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.video_content__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    font-weight: 700;
    margin-bottom: 14px;
}
.video_content__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    background: #f8f8f8;
    margin-bottom: 29px;
}
.video_content__placeholder iframe {
    aspect-ratio: 16 / 9;
	height: 100%;
	width: 100%;
}
.video_content__placeholder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.video_content__description {
    padding: 0 40px;
}
.video_content .delivery_addresses__popup__close {
    top: 25px;
    right: 32px;
}
/* Videos */

/* Feedback */
.feedback__columns {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 55px;
}
.feedback__columns__grey {
    color: #FFF;
    background: #999;
    padding: 41px 38px 36px 46px;
    border-radius: 5px;
    margin-bottom: 37px;
}
.feedback__columns__grey__header {
    font-size: 20px;
    line-height: var(--lineheight);
    margin-bottom: 20px;
    font-weight: 700;
}
.feedback__columns__grey__text {
    margin-bottom: 24px;
}
.feedback__columns__grey__view_policy {
    font-size: 16px;
    line-height: 24px;
    color: #FFf;
    font-weight: 600;
}
.feedback__columns__grey__view_policy svg {
    margin-right: 10px;
}
.feedback_account_options {
    font-size: 20px;
    line-height: var(--lineheight);
    margin-bottom: 40px;
    font-weight: 700;
}
.feedback_account_options__grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 54px;
}
.feedback_account_options__grid a {
    display: block;
    height: 190px;
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    color: var(--darkgrey);
    text-align: center;
    padding: 55px;
    font-size: 16px;
    font-weight: 600;
}
.feedback_account_options__grid a:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.feedback_account_options__grid a svg {
    display: block;
    margin: 0 auto 21px auto;
}
.feedback_account_options__grid a:hover svg path {
    stroke: var(--maincolor);
}
.feedback_account_options__grid:hover svg circle {
    stroke: var(--maincolor);
}

.feedback__history__header {
    font-size: 20px;
    line-height: var(--lineheight);
    margin-bottom: 19px;
    font-weight: 700;
}
.feedback__hr {
    margin-bottom: 50px;
}
.feedback_policy {
    text-decoration: underline;
    cursor: pointer;
}
.feedback_policy:hover {
    color: var(--maincolor);
}
/* Feedback */


/* Submit Feedback */
.submit_feedback__columns {
    display: grid;
    grid-template-columns: 1fr 41%;
    gap: 2%;
}
.submit_feedback__columns__support {
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 41px 42px 46px 38px;
    margin-bottom: 40px;
}
.submit_feedback__columns__support__text {
    margin-bottom: 25px;
}
.submit_feedback__columns__support__button {
    line-height: 24px;
    font-weight: 600;
    color: var(--darkgrey)
}
.submit_feedback__columns__support__button svg {
    margin-right: 10px;
}
.submit_feedback__columns__support__button svg path {
    stroke: var(--darkgrey);
}
.submit_feedback__form__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    margin-bottom: 20px;
}
.submit_feedback__form__grid label {
    line-height: 50px;
    font-weight: 600;
    white-space: nowrap;
}
.uploadattachments_label {
    width: 254px;
}
.uploadattachments_label input[type="file"] {
    display: none;
}
.feedback_term_of_sale input {
    margin-right: 15px;
    height: 16px;
    width: 16px;
    cursor: pointer;
}
.feedback_term_of_sale span {
    text-decoration: underline;
    color: var(--darkgrey);
    cursor: pointer;
}
.feedback_term_of_sale span:hover {
    color: var(--maincolor);
}
.feedback_label_checkbox,
.feedback_term_of_sale {
    display: block;
    line-height: 26px;
    margin-bottom: 20px;
}
.feedback_term_of_sale {
    display: flex;
    padding: 26px 0px;
    align-items: center;
}
.feedback_label_checkbox input[type="checkbox"] {
    margin-right: 18px;
}
.feedback_label_checkbox a {
    color: var(--darkgrey);
    text-decoration: underline;
}
.feedback_label_checkbox a:hover {
    color: var(--maincolor);
}
.submit_feedback__uploaded_images {
    display: flex;
    flex-wrap: wrap;
}
.submit_feedback__uploaded_image {
    display: flex;
    width: 100px;
    height: 100px;
    border: 3px solid #F2F2F2;
    margin-right: 20px;
    margin-bottom: 20px;
    margin: 20px 20px 20px 0;
}
.submit_feedback__uploaded_image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
/* Submit Feedback */

/* Feedback Status*/
.feedback_status__columns {
    display: grid;
    grid-template-columns: 1fr 30%;
    gap: 2%;
}
.feedback_status__details {
    background: #f8f8f8;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 31px 31px 29px 41px;
    margin-bottom: 40px;
}
.feedback_status__details__table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.feedback_status__details__table strong {
    font-weight: 600;
}
.feedback_status__details__button {
    color: var(--darkgrey);
    font-weight: 600;
    line-height: 24px;
}
.feedback_status__details__button svg {
    margin-right: 10px;
}
.feedback_status__columns__grey {
    padding: 27px 34px 35px 40px;
    background: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 42px;
}
.feedback_status__columns__grey__sent_by {
    font-size: 14px;
    line-height: var(--lineheight);
    margin-bottom: 38px;
}
.feedback_status__columns__grey__text {
    line-height: 26px;
    margin-bottom: 24px;
}
.feedback_status__columns__grey__attachments__header {
    line-height: var(--lineheight);
    font-weight: 600;
    margin-bottom: 17px;
}
.feedback_status__columns__grey__attachments__images {
    display: flex;
    flex-wrap: wrap;
}
.feedback_status__columns__grey__attachments__image {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d9d9d9;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.feedback_status__columns__grey__attachments__image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.feedback_status__reply {
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 40px 33px 42px 39px;
    margin-bottom: 42px;
}
.feedback_status__upload input[type="file"] {
    display: none;
}
.feedback_status__checkbox {
    display: block;
    margin-bottom: 20px;
}
.feedback_status__checkbox input[type="checkbox"] {
    margin-right: 16px;
}
/* Feedback Status*/

/* Feedback received */
.feedback_received__columns {
    display: grid;
    grid-template-columns: 1fr 41%;
    gap: 2%;
}
.feedback_received__red {
    background: var(--maincolor);
    border-radius: 5px;
    padding: 41px 38px 52px 46px;
    margin-bottom: 40px;
    color: #FFF;
}
.feedback_received__red__text {
    line-height: 26px;
    margin-bottom: 26px;
}
.feedback_received__red__button {
    font-weight: 600;
    color: #FFF;
}
.feedback_received__red__button svg {
    margin-right: 10px;
}
.feedback_received__red__button svg path {
    stroke: #FFF;
}
.feedback_received__grid {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
.feedback_received__grid a {
    position: relative;
    display: block;
    height: 190px;
    text-align: center;
    background: #f2f2f2;
    padding: 56px 10px 0 10px;
    font-weight: 600;
    color: var(--darkgrey);
    border-radius: 5px;
    border: 1px solid #d9d9d9;
}
.feedback_received__grid a svg {
    display: block;
    margin: 0 auto 20px auto;
}
.feedback_received__grid a:hover {
    color: var(--maincolor);
    border-color: var(--maincolor);
}
.feedback_received__grid a:hover svg path {
    stroke: var(--maincolor);
}
.grid_alert {
    position: absolute;
    top: 53px;
    left: calc(50% + 11px);
    width: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 50%;
}
/* Feedback received */

/* Search page */
.search_page .ais-SearchBox-form input[type="search"] {
    margin: 0 0 30px 0;
}
/* Search page */
/* .agGridContainer .ag-header-cell-label {
  justify-content: center;
} */