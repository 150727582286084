@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/css/flag-icons.min.css');

/* product listing */

.products_listing {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    margin-bottom: 40px;
    gap: 20px;
}
.products_listing__three_columns {
    grid-template-columns: repeat(3, 1fr);
}

.products_listing.list {
    grid-template-columns: 1fr;
}
.products_listing.list .product_in_listing {
    position: relative;
    display: grid;
    grid-template-columns: 372px 1fr 1fr;
    gap: 0 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}
.product_in_listing__second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.product_in_listing__first, .product_in_listing__second, .product_in_listing__third {
    position: relative;
}
.products_listing.list .product_in_listing__first::after,
.products_listing.list .product_in_listing__second::after {
    content: '';
    position: absolute;
    width: 1px;
    top: 0;
    right: -20px;
    bottom: 0;
    background: #d9d9d9;
}

.product_in_listing {
    position: relative;
    padding: 14px 21px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: var(--transition);
}
.product_in_listing:hover, .product_in_listing.incart {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.product_in_listing__link {
    display: block;
    margin-bottom: 20px;
}
.product_in_listing__picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 176px;
    margin: 0 auto 1rem auto;
}
.product_in_listing__image {
    object-fit: contain;
    width: auto;
    height: 176px;
    max-width: 100%;
}
.product_in_listing__title {
    display: block;
    font-size: 1rem;
    line-height: 1.375em;
    height: 2.75em;
    overflow: hidden;
    font-weight: 700;
    color: var(--darkgrey);
    max-width: 345px;
}
.product_in_listing__title_word_break {
    word-break: break-all
}
.products_listing.list .product_in_listing__title {
    height: auto;
    max-height: 2.75em;
}
.product_in_listing__link:hover .product_in_listing__picture img {
    cursor: pointer;
}
.product_in_listing__link:hover .product_in_listing__title {
    color: var(--maincolor);
    cursor: pointer;
}
.product_in_listing__table_price_grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.product_in_listing__table_price {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
.product_in_listing__table_price>div {
    position: relative;
}
.product_in_listing__table_price>div:not(.nodata)::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -10px;
    width: 1px;
    background: #d9d9d9;
}
.product_in_listing__table_price::after {
    left: auto;
    right: 32%;
}
.product_in_listing__table_price.hide_old_prices .product_in_listing__table_price__second {
    display: none;
}
.product_in_listing__table_price.hide_old_prices::after {
    display: none;
}
.regular_price, .regular_price_grid {
    display: inline-block;
    vertical-align: top;
    font-size: 1.25rem;
    line-height: var(--lineheight);
    font-weight: 700;
}
.regular_price_grid {
    color: #999999;
    text-decoration-line: line-through
}
.regular_discount {
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    border-radius: 5px;
    background: var(--monolithlightyellow);
    color: #000;
    font-size: 1.25rem;
    line-height: var(--lineheight);
    font-weight: 700;
    margin: 0 0 0 9px;
}
.regular_capacity {
    font-size: .875rem;
    line-height: var(--lineheight);
}
.product_in_listing__table_price__second {
    color: #d9d9d9;
}
.regular_vat_gros {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 15px 10px;
    font-size: .875rem;
    line-height: var(--lineheight);
    margin-top: 10px;
    white-space: nowrap;
    margin-right: 20px;
}
.regular_vat_gros > div {
    display: flex;
    justify-content: space-between;
}
.regular_rrp {
    margin-top: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px 10px;
    font-size: .875rem;
    line-height: var(--lineheight);
}
.regular_vat_gros span,
.regular_rrp span {
    color: #999;
}

.product_in_listing__spec__price {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 10px;
}
.product_in_listing__spec {
    list-style: none;
    font-size: 0.875rem;
    line-height: 1.36em;
}
.product_in_listing__spec__brand {
    height: 3.05em;
    overflow: hidden;
    position: relative;
    cursor: default;
    width: max-content;
}
.product_in_listing__spec li {
    margin-bottom: 11px;
    display: grid;
    grid-template-columns: 30px 1fr;
}
.product_in_listing__price {
    text-align: center;
}
.actual_price {
    font-size: 1.25rem;
    line-height: 1.362em;
    font-weight: 700;
}
.old_price {
    position: relative;
    font-weight: 700;
    text-decoration: line-through;
    color: #999;
    font-size: 1.25rem;
    line-height: 1.362em;
}
.price_discount {
    display: inline-block;
    padding: 0 5px;
    font-size: 1.25rem;
    line-height: 1.5em;
    font-weight: 700;
    background: #FFD600;
    border-radius: 5px;
    margin-bottom: 2px;
}
.products_listing.list .hide_on_listview {
    display: none;
}
.priceunits {
    font-size: .875rem;
    line-height: 1.357em;
}
.product_in_listing__code {
    position: absolute;
    top: 0;
    left: 0;
    font-size: .875rem;
    line-height: 1.357em;
    color: #999;
    background: white;
}
.product_in_listing__heart,
.product_supplied_partner,
.product_supplied_partner__preview_product {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
}
.product_supplied_partner {
    top: 36px;
    cursor: default;
}
.product_supplied_partner__preview_product {
    top: 70px;
    right: 34px;
    cursor: default;
}
.product_in_listing__heart img {
    pointer-events: none;
}
.product_in_listing__third .product_in_listing__heart {
    display: none;
}
.products_listing.list .product_in_listing__third .product_in_listing__heart {
    display: block;
}
.products_listing.list .product_in_listing__first .product_in_listing__heart {
    display: none;
}
.product_in_listing__heart .heart_red {
    display: none;
}
.product_in_listing__heart.true .heart_grey {
    display: none;
}
.product_in_listing__heart.true .heart_red {
    display: block;
}
.stock_status {
    position: relative;
    font-size: .875rem;
    line-height: 40px;
    white-space: nowrap;
    text-align: start;
    cursor: default;
}
.stock_status.instock {
    color: #00B707;
}
.stock_status.low {
    color: #FC6738;
}
.stock_status.outofstock {
    color: var(--maincolor);
}
.stock_status::before {
    content: '';
    position: relative;
    top: 1px;
    display: inline-block;
    margin: 0 15px 0 5px;
    width: 13px;
    height: 13px;
    background: var(--darkgrey);
    border-radius: 50%;
}
.stock_status.instock::before {
    background-color: #00B707;
}
.stock_status.low::before {
    background-color: #FC6738;
}
.stock_status.outofstock::before {
    background-color: var(--maincolor);
}
.cart_stock {
    display: grid;
    grid-template-columns: 1fr 135px;
    gap: 0 11px;
}
.put_to_cart {
    width: 120px;
    height: 36px;
    color: #FFF;
    background-color: var(--maincolor);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.put_to_cart_big_size {
    width: 135px;
}
.put_to_cart_notempty {
    background-color: #95ff99;
}
.put_to_cart.normal .cart_icon,
.put_to_cart.waiting .spinner_ring {
    display: inline;
}
.put_to_cart.waiting .cart_icon,
.put_to_cart.normal .spinner_ring {
    display: none;
}
.product_badges {
    list-style: none;
    position: absolute;
    top: 33px;
    left: 0;
    pointer-events: none;
}
.product_badges>li {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}
.badge-country {
    border: 1px solid #d9d9d9;
}
.div_clickedoncart {
    display: grid;
    grid-template-columns: 40px 55px 40px;
    height: 36px;
}
.clickedoncart .div_clickedoncart {
    display: grid
}
.clickedoncart .div_notclickedoncart {
    display: block;
}
.remove_from_cart {
    border: 1px solid #d9d9d9;
    border-radius: 5px 0 0 5px;
}
.cart_amount_input {
    text-align: center;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}
.put_to_cart__small_plus,
.put_to_cart__small_minus {
    border-radius: 0 5px 5px 0;
    color: #FFF;
    font-size: 1.6rem;
    background: #00B707;
}
.put_to_cart__small_minus {
    border-radius: 5px 0 0 5px;
    background: #FFF;
    color: var(--darkgrey);
    border: 1px solid #d9d9d9;
}
.put_to_cart__small svg, .remove_from_cart svg {
    position: relative;
    top: 2px;
}
.star_rating {
    margin: 5px auto 21px auto;
    width: 130px;
    font-size: .875rem;
    line-height: 1.357em;
    text-align: center;
}
.products_listing.list .star_rating {
    margin-bottom: 0;
}
.ratingvotes::before {
    content: '(';
}
.ratingvotes::after {
    content: ')';
}
.ratingvotes {
    position: relative;
    top: -2px;
    font-size: 14px;
    color: #999;
    margin-left: 8px;
}
.starwrapper {
    position: relative;
    white-space: nowrap;
    cursor: pointer;
}
.starwrapper * {
    pointer-events: none;
}
.starwrapper svg {
    display: inline-block;
    vertical-align: baseline;
    width: 14px;
    height: auto;
    margin: 0 2px;
}
.starsnotempty {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;
}
.rating_popup__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    visibility: hidden;
}
.rating_popup__wrapper.active {
    visibility: visible;
}
.rating_popup__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    transition: var(--transition);
    opacity: 0;
    z-index: 1;
}
.rating_popup__wrapper.active .rating_popup__overlay {
    opacity: 1;
}
.rating_popup__content {
    position: absolute;
    top: 90px;
    width: 518px;
    left: calc(50% - 259px);
    background: #FFF;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 50px 56px 48px 56px;
    z-index: 2;
    transition: var(--transition);
    transform: translateY(100px) scale(.5);
    opacity: 0;
}
.rating_popup__wrapper.active .rating_popup__content {
    transform: translateY(0) scale(1);
    opacity: 1;
}
.rating_popup__title {
    text-align: center;
    font-weight: 700;
}
.rating_popup__img {
    display: flex;
    justify-content: center;
    width: 92px;
    height: 92px;
    margin: 16px auto 20px auto;
}
.rating_popup__img img {
    object-fit: contain;
}
.product_in_listing__second .product_in_listing__spec {
    display: none;
}
.products_listing.list .product_in_listing__second .product_in_listing__spec {
    display: block;
}
.products_listing.list .product_in_listing__third .product_in_listing__spec__price {
    display: block;
}
.products_listing.list .product_in_listing__third .product_in_listing__spec {
    display: none;
}

.vat_gros_pvp {
    width: 100%;
    border: 0 none;
    font-size: .875rem;
    line-height: 1.357em;
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 0;
    margin-top: 11px;
    display: none;
    border-collapse: collapse;
}
.products_listing.list .vat_gros_pvp {
    display: table;
}
.vat_gros_pvp tr:first-child {
    border-bottom: 1px solid #d9d9d9;
}
.vat_gros_pvp td {
    padding: 5px 0;
}
.vat_gros_pvp tr td:first-child {
    color: #999;
}
.vat_gros_pvp td div {
    padding: 5px 0;
}
.vat_gros_pvp td div svg {
    margin-right: 26px;
}

.product_in_listing__spec_icons {
    list-style: none;
    display: none;
    flex-wrap: wrap;
}
.product_in_listing__spec_icons li {
    position: relative;
    margin-right: 22px;
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.product_in_listing__spec_icons li[data-title]::after {
    content: attr(data-title);
    position: absolute;
    padding: 0 17px;
    top: -38px;
    left: 0;
    white-space: nowrap;
    font-size: .875rem;
    line-height: 2em;
    background: #FFF;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 10%);
    border-radius: 5px 5px 5px 0;
    z-index: 1;
    pointer-events: none;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    color: #727272;
}
.product_in_listing__spec_icons li[data-title]:hover::after,
.product_supplied_partner:hover > div,
.product_in_listing__heart:hover > div,
.product_supplied_partner__preview_product:hover > div,
.product_page__price:hover > div,
.strong:hover > div,
.product_in_listing__code:hover > div,
.product_brand_grid:hover > div,
.prod_qty_in_box:hover > div,
.price_area__net:hover > div,
.product_in_listing__spec__brand:hover > div,
.regular_vat_gros_popup:hover > div,
.product_page__favorite:hover > div,
.product_page__spec_table__unit_popup:hover > div,
.stock_status:hover > div {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.product_supplied_partner > div > div,
.product_supplied_partner__preview_product > div > div,
.product_in_listing__code > div,
.prod_qty_in_box > div,
.product_page__spec_table__unit_popup > div,
.product_in_listing__heart > div {
    width: max-content;
    max-width: 330px;
}
.product_supplied_partner > div,
.product_in_listing__heart > div,
.product_in_listing__code > div,
.product_supplied_partner__preview_product > div,
.product_page__price > div,
.strong > div,
.product_brand_grid > div,
.prod_qty_in_box > div,
.price_area__net > div,
.product_in_listing__spec__brand > div,
.regular_vat_gros_popup > div,
.product_page__favorite > div,
.product_page__spec_table__unit_popup > div,
.stock_status > div {
    position: absolute;
    bottom: 26px;
    z-index: 15;
    padding: 5px 10px 5px 17px;
    border-radius: 5px 5px 5px 0px;
    border: 1px solid var(--400, #D9D9D9);
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.10);
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);

    color: #727272;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.product_in_listing__heart > div {
    right: 0px;
    border-radius: 5px 5px 0px 5px;
    white-space: pre-wrap;
}
.product_page__spec_table__unit_popup > div {
    right: 0px;
    border-radius: 5px;
}
.product_in_listing__spec__brand > div {
    bottom: inherit;
    top: 16px;
    border-radius: 0px 5px 5px 5px;
    padding: 3px 10px 3px 17px;
}
.product_page__favorite > div {
    border-radius: 5px 5px 0px 5px;
    bottom: 24px;
    right: 0;
}
.prod_qty_in_box > div {
    bottom: 90px;
}
.prod_qty_in_box__list > div {
    bottom: 65px;
}
.product_brand_grid > div,
.stock_status > div {
    bottom: 35px;
}
.product_page__price > div,
.strong > div {
    top: 38%;
    bottom: inherit;
}
.regular_vat_gros_popup > div {
    bottom: inherit;
    border-radius: 0px 5px 5px 5px;
    padding: 3px 10px 3px 17px;
}
.regular_vat_gros_popup {
    color: #999;
    cursor: default;
}
.prod_qty_in_box {
    cursor: default;
}
.price_area__net > div {
    bottom: 85px;
}
.product_page__price_down > div {
    top: 58%;
    border-radius: 0px 5px 5px 5px;
}
.product_page__price_list > div {
    top: 32%;
}
.product_page__price_down_list > div {
    top: 97%;
    border-radius: 0px 5px 5px 5px;
}
.product_supplied_partner__preview_product > div {
    border-radius: 5px 5px 0px 5px;
    right: 0;
}
.product_supplied_partner__tree > div {
    border-radius: 5px 5px 0px 5px;
    right: 0;
}
.product_in_listing__spec_icons svg {
    width: 32px;
    height: 32px;
}
.products_listing.list .product_in_listing__spec_icons {
    display: flex;
}
.product_in_listing__history {
    position: absolute;
    top: calc(50% - 9px);
    right: 30px;
}
.product_history__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    visibility: hidden;
}
.product_history__wrapper.active {
    visibility: visible;
}
.product_history__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    transition: var(--transition);
    z-index: 1;
    opacity: 0;
}
.product_history__wrapper.active .product_history__overlay {
    opacity: 1;
}
.product_history__popup {
    position: absolute;
    top: 90px;
    width: 694px;
    left: calc(50% - 347px);
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 15px 20px 22px 20px;
    z-index: 2;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(100px) scale(.5);
}
.product_history__wrapper.active .product_history__popup {
    opacity: 1;
    transform: translateY(0) scale(1);
}
.product_history__popup__header {
    font-size: 1.25rem;
    line-height: 1.35em;
    margin: 0 0 6px 0;
    font-weight: 700;
}
.product_history__popup__close, .rating_popup__close {
    position: absolute;
    top: 19px;
    right: 22px;
    width: 21px;
    height: 21px;
    border: 1px solid #999;
    border-radius: 50%;
}
.rating_popup__close {
    top: 12px;
    right: 12px;
}
.star-rating {
    font-size: 0;
    white-space: nowrap;
    display: block;
    width: 263px;
    height: 50px;
    margin: 0 auto 24px auto;
    overflow: hidden;
    position: relative;
    background-size: contain;
}
.star-rating i {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 1;
    background-size: contain;
}
.star-rating input {
    appearance: none;
    opacity: 0;
    display: inline-block;
    width: 20%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
    cursor: pointer;
}
.star-rating input:hover + i,
.star-rating input:checked + i {
    opacity: 1;
}
.star-rating i ~ i {
    width: 40%;
}
.star-rating i ~ i ~ i {
    width: 60%;
}
.star-rating i ~ i ~ i ~ i {
    width: 80%;
}
.star-rating i ~ i ~ i ~ i ~ i {
    width: 100%;
}
.rating_popup__summary {
    display: flex;
    justify-content: space-around;
    margin: 33px 0 0 0;
    font-size: .875rem;
    line-height: 1.357em;
    color: #999;
}
.rating_popup__success {
    color: #FFF;
    background: #00B707;
    border-radius: 5px;
    font-size: .875rem;
    line-height: 40px;
    padding: 0 22px;
    margin-top: 24px;
}
.rating_popup__wrapper.voted .rating_popup__success {
    display: block;
}
.rating_popup__success svg {
    position: relative;
    top: 11px;
    margin-right: 5px;
}
.rating_popup__wrapper.voted .hide_on_voted {
    display: none;
}
.product_history__popup__close::before,
.product_history__popup__close::after,
.rating_popup__close::before,
.rating_popup__close::after
{
    content: '';
    position: absolute;
    top: 4px;
    left: 9px;
    width: 1px;
    height: 11px;
    transform: rotate(45deg);
    background: #999;
}
.product_history__popup__close::after,
.rating_popup__close::after
{
    transform: rotate(-45deg);
}
.product_history__popup__close:hover,
.rating_popup__close:hover
{
    border-color: var(--darkgrey);
}
.product_history__popup__close:hover::before,
.product_history__popup__close:hover::after,
.rating_popup__close:hover::before,
.rating_popup__close:hover::after
{
    background: var(--darkgrey);
}
.product_history__popup__prodinfo {
    display: grid;
    grid-template-columns: 92px 1fr;
    gap: 0 23px;
    margin-bottom: 16px;
}
.product_history__popup__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92px;
}
.product_history__popup__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.product_history__popup__prodtitle {
    padding: 23px 0 14px 0;
    font-weight: 700;
}
.product_history__popup__prodshortinfo {
    color: #999;
    font-size: .875rem;
    line-height: 1.357em;
    margin-right: 29px;
}
.product_history__popup__prodshortinfo svg {
    position: relative;
    top: 4px;
    margin-right: 10px;
}
.product_history__popup__prodshortinfo svg path {
    stroke: #999;
}
.product_history__popup__table__wrapper {
    max-height: calc(100vh - 330px);
    overflow-y: auto;
}
.product_history__popup__table {
    width: 100%;
    border-collapse: collapse;
    line-height: 50px;
}
.product_history__popup__table thead {
    background: #d9d9d9;
    font-weight: 700;
    text-align: center;
}
.product_history__popup__table tbody {
    text-align: center;
}
.product_history__popup__table tr:nth-child(even) {
    background: #f2f2f2;
}
.product_history__popup__table a {
    color: var(--darkgrey);
    text-decoration: underline;
}
.product_history__popup__table a:hover {
    color: var(--maincolor);
}
.wrapper_product_brand_grid {
    display: flex;
    justify-content: space-between;
    margin: 0 15px 10px 0;
}
.wrapper_content_regular_price_grid{
    display: flex;
    justify-content: space-between;
    margin-right: 15px
}
.wrapper__old_price{
    display: flex;
    justify-content: space-between;
    margin: 0 15px 10px 0;
}
.wrapper__price{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.display_grid{
    display: grid;
}
.product_brand, .product_brand_grid {
    font-size: .875rem;
    line-height: 1.357em;
    margin-bottom: 10px;
    display: block;
    width: calc(100% - 25px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.product_brand_grid {
    margin: 5px 5px 0 0;
    max-width: 245px;
    width: max-content;
}
.product_brand svg {
    margin-right: 15px;
}
.product_brand_grid svg {
    margin-right: 15px;
}
.wrapper.list .product_brand {
    display: none;
}
/* product listing */

/* product page */
.product_page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 93px;
    margin-bottom: 60px;
}
.product_page__images_and_badges {
    display: grid;
    grid-template-columns: 40px 1fr 50px;
}
.product_page__badges {
    list-style: none;
    display: flex;
    flex-direction: column;
}
.product_page__badges li {
    width: 40px;
    height: 40px;
    margin-bottom: 40px;
    border-radius: 50%;
}
.product_page__badges li svg {
    width: 40px;
    height: 40px;
}
.badge_country {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 0.5px var(--grey) solid;
}
.product_page__images__main {
    display: flex;
    max-height: 500px;
}
.product_page__images__main img {
    object-fit: contain;
    width: 100%;
    height: auto;
}
.product_page__images__additional img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    cursor: pointer;
}
.product_page h1 {
    font-size: 1.5rem;
    line-height: 1.362em;
    padding-bottom: 10px;
}
.product_page__big_icons {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
}
.product_page__big_icons li {
    position: relative;
    margin-right: 22px;
    width: 32px;
    height: 32px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.product_page__big_icons li[data-title]::after,
.product_page__big:last-child [data-title]::after {
    content: attr(data-title);
    position: absolute;
    padding: 0 17px;
    top: -38px;
    left: 0;
    white-space: nowrap;
    font-size: .875rem;
    line-height: 2em;
    background: #FFF;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 10%);
    border-radius: 5px 5px 5px 0;
    z-index: 1;
    pointer-events: none;
    transition: var(--transition);
    visibility: hidden;
    opacity: 0;
    transform: translateY(5px);
    color: #727272;
}
.product_page__big_icons li[data-title]:hover::after,
.product_page__big:last-child [data-title]:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
.product_page__price_area {
    position: relative;
    padding: 43px 34px;
    background: #f8f8f8;
    margin-bottom: 13px;
}
.product_page__favorite {
    position: absolute;
    top: 37px;
    right: 35px;
    z-index: 1;
}
.product_page__favorite svg {
    width: 21px;
    height: auto;
}
.product_page__favorite.false .favorite_true,
.product_page__favorite.true .favorite_false {
    display: none;
}
.product_page__favorite.false .favorite_false,
.product_page__favorite.true .favorite_true {
    display: block;
}
.product_page__price_area__table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d9d9d9;
    font-size: .875rem;
    line-height: 1.357em;
    margin-bottom: 40px;
}
.product_page__price_area__table td {
    padding: 15px 22px;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}
.product_page__price_area__table tr:first-child td {
    padding-bottom: 3px;
}
.product_page__price_area__table tr:last-child td {
    padding-top: 3px;
}
.product_page__price_area__table svg {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 26px;
}
.product_page__price_area__table .strong {
    color: #999;
    cursor: default;
}
.table_old_value {
    color: #999;
    margin-right: 15px;
    text-decoration: line-through;
}
.price_area__wrapper {
    display: grid;
    margin-bottom: 1rem;
}
.price_area__wrapper.with_sale {
    grid-template-columns: auto 1fr;
    gap: 57px;
}
.price_area__wrapper__actual {
    position: relative;
}
.price_area__discount {
    position: absolute;
    top: -9px;
    left: 0;
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
    color: #000;
    background: #FFD600;
    padding: 0 15px;
    border-radius: 5px;
}
.price_area__net {
    color: #999;
    width: max-content;
}
.price_area__big_price {
    position: relative;
    font-size: 2rem;
    line-height: 1.362em;
    font-weight: 700;
}
.price_area__wrapper__old {
    display: none;
    color: #999;
}
.price_area__wrapper.with_sale .price_area__wrapper__old {
    display: block;
}
.price_area__wrapper__old .price_area__big_price::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 24px;
    height: 2px;
    background: #999;
}
.price_area__units {
    font-size: 0.875rem;
    line-height: 1.36em;
}
.price_area__footer {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0 30px;
}
.price_area__footer__info {
    display: flex;
    align-items: center;
    padding-left: 38px;
    font-size: .875rem;
    line-height: var(--lineheight);
    color: #999;
    background-position: left top 13px;
    background-repeat: no-repeat;
}
.price_area__footer__info svg {
    position: relative;
    top: 13px;
    margin-right: 10px;
}
.put_to_cart_big {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 178px;
    height: 50px;
    color: #FFF;
    background: var(--maincolor);
    border-radius: 5px;
}
.put_to_cart_big .spinner_ring {
    transform: scale(.4);
}
.put_to_cart_big.normal .cart_icon,
.put_to_cart_big.waiting .spinner_ring {
    display: inline;
}
.put_to_cart_big.waiting .cart_icon,
.put_to_cart_big.normal .spinner_ring {
    display: none;
}

.product_page__spec_table {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* gap: 0 72px; */
    gap: 0 36px;
    padding: 8px 0;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.product_page__spec_table>div:first-child {
    margin-left: 25px;
}
.product_page__spec_table::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background: #d9d9d9;
}
.product_page__spec_table__unit {
    display: grid;
    grid-template-columns: 18px 120px 1fr;
    gap: 0 22px;
    font-size: .875rem;
    line-height: 1.357em;
    padding: 5px 0;
}
.product_page__spec_table__unit div:not(.available_units):nth-child(2) {
    color: #999;
    font-weight: 700;
    position: relative;
    cursor: default;
}
.product_page__spec_table>div:last-child .product_page__spec_table__unit {
    grid-template-columns: 18px 140px 1fr;
}
.product_page__spec_table__unit:last-child>div:nth-child(3) {
    width: 120px;
}

.product_page_tabs__tabs {
    display: flex;
}
.product_page_tabs__tab {
    position: relative;
    top: 1px;
    line-height: 48px;
    border: 1px solid #d9d9d9;
    background: #f2f2f2;
    border-radius: 5px 5px 0 0;
    padding: 0 40px;
    margin-right: 10px;
}
.product_page_tabs__tab:hover {
    background: #f8f8f8;
}
.product_page_tabs__tab.active {
    color: #FFF;
    background: var(--maincolor);
    cursor: default;
}
.product_page_tabs__content {
    border: 1px solid #d9d9d9;
    border-radius: 0 0 5px 5px;
    padding: 33px 30px 7px 34px;
    margin-bottom: 40px;
}
.product_page_tabs__content__unit {
    display: none;
}
.product_page_tabs__content__unit.active {
    display: block;
}
.product_page_tabs__content h4 {
    font-size: 1em;
    padding-bottom: 0;
    font-weight: 700;
}
.product_page_tabs__content p {
    padding-bottom: 1em;
}
.product_page_tabs__table {
    width: 100%;
    border-collapse: collapse;
    line-height: 40px;
    margin-bottom: 59px;
}
.product_page_tabs__table tr:nth-child(even) {
    background: #f2f2f2;
}
.product_page_tabs__table th, .product_page_tabs__table td {
    padding: 0 10px;
}
.product_page_tabs__table tr th:last-child, .product_page_tabs__table tr td:last-child {
    text-align: right;
    padding-right: 26px;
}
.product_page_tabs__table thead {
    text-align: left;
    background: #d9d9d9;
    border-radius: 5px;
}
.product_page_tabs__content__unit__star {
    margin-bottom: 49px;
}
.breadcrumbs__placeholder {
    list-style: none;
    display: flex;
    margin: 21px 0;
}
.breadcrumbs__placeholder li {
    height: 1.5rem;
    width: 2rem;
    margin: 0 1rem 0 0;
}
.breadcrumbs__placeholder li:nth-child(2) {
    width: 8rem;
}
.breadcrumbs__placeholder li:nth-child(3) {
    width: 16rem;
}
.productpage_grid__placeholder {
    display: grid;
    grid-template-columns: 40px 1fr 50px 1fr;
    gap: 0 40px;
}
.badge__placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 0 40px 0;
}
.productpage_main_image__placeholder {
    height: 500px;
}
.productpage_thumbs__placeholder {
    list-style: none;
}
.productpage_thumbs__placeholder li {
    width: 50px;
    height: 50px;
    margin: 0 0 25px 0;
}
.productpage_h1__placeholder {
    height: 2rem;
    margin: 0 0 1.5rem 0;
}
.productpage_icons__placeholder {
    display: flex;
    list-style: none;
    margin: 0 0 32px 0;
}
.productpage_icons__placeholder li {
    list-style: none;
    width: 32px;
    height: 32px;
    margin: 0 22px 0 0;
    border-radius: 50%;
}
.productpage_pricearea__placeholder {
    height: 350px;
}
.minus_plus_input {
    display: grid;
    grid-template-columns: 50px 80px 50px;
}
.minus_plus_input__button {
    font-size: 2rem;
    line-height: 48px;
    border: 1px solid #d9d9d9;
    background: #FFF;
    color: var(--darkgrey);
}
.minus_plus_input__button.plus {
    color: #FFF;
    background: #00B707;
    border-radius: 0 5px 5px 0;
}
.minus_plus_input__button.minus {
    border-radius: 5px 0 0 5px;
}
.minus_plus_input__button svg {
    margin-top: 15px;
}
.minus_plus_input__input {
    line-height: 48px;
    border: 0 none;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    text-align: center;
}
.order_history_units__title {
    font-weight: 700;
    color: var(--darkgrey);
    line-height: 50px;
}
.order_history_units__title:hover {
    color: var(--grey);
}
.order_history_unit, .order_history_unit_grid {
    margin-left: -38px;
    font-size: .875rem;
    color: var(--darkgrey);
    margin-bottom: 7px;
}
.order_history_unit_grid {
    margin-bottom: 0;
    margin-left: 0;
}
.stock_round {
    position: relative;
    top: 1px;
    left: 3px;
    display: inline-block;
    width: 13px;
    height: 13px;
    background: #999;
    border-radius: 50%;
}
.instock {
    color: #00B707;
}
.instock .stock_round {
    background: #00B707;
}
.low {
    color: #FC6738;
}
.low .stock_round {
    background: #FC6738;
}
.outofstock {
    color: var(--maincolor);
}
.outofstock .stock_round {
    background: var(--maincolor);
}
.btn-back {
    position: relative;
    padding-left: 59px;
}
.btn-back svg {
    position: absolute;
    top: 13px;
    left: 21px;
}
.wrapper_favorite_product_page {
    position: absolute;
    top: 30px;
    right: 30px;
}
/* product page */

.loading_new_data {
    margin-top: 20px;
    color: #FFF;
    background: var(--maincolor);
    padding: 10px;
    text-align: center;
}

.history_button {
    text-align: left;
}
.history_button span {
    display: block;
    font-size: .875rem;
    line-height: var(--lineheight);
    pointer-events: none;
}

.third_column_inbox {
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1.36em;
}
.third_column_inbox svg {
    margin: 0 9px 0 -2px;
}
.wrapper.grid .third_column_inbox {
    display: none;
}

.prod_qty_in_box {
    display: flex;
    align-items: center;
    font-size: .875rem;
    /* position: absolute; */
}
.prod_qty_in_box svg {
    margin: 0 15px 0 0;
}
.wrapper_star_rating_product_icons {
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
}
/* @media screen and (max-width:1439px) {
    .products_listing {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (max-width:1120px) {
    .products_listing {
        grid-template-columns: repeat(2, 1fr);
    }
} */
@media screen and (max-width:1380px) {
    .product_page__spec_table__unit {
        display: grid;
        grid-template-columns: 18px 110px 1fr;
        gap: 0 6px;
        font-size: 12px;
    }
    .product_page__spec_table {
        gap: 0 16px;
    }
    .product_page__spec_table>div:first-child {
        margin-left: 10px;
    }
}

/* start product page modal */
.product_page_modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    visibility: hidden;
}
.product_page_modal__wrapper.active {
    visibility: visible;
    overflow: auto;
}
.product_page_modal__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    transition: var(--transition);
    z-index: 1;
    opacity: 0;
}
.product_page_modal__wrapper.active .product_page_modal__overlay {
    opacity: 1;
}
.product_page_modal__popup {
    position: absolute;
    top: 90px;
    left: 2%;
    width: 96%;
    background: #FFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 40px 20px;
    z-index: 100;
    transition: var(--transition);
    opacity: 0;
    transform: translateY(100px) scale(.5);
}
.product_page_modal__popup .ais-Hits-list{
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr))
}
.product_page_modal__wrapper.active .product_page_modal__popup {
    opacity: 1;
    transform: translateY(0) scale(1);
}
.product_page_modal__popup__header {
    display: flex;
    justify-content: space-between;
}
/* end product page modal */